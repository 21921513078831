@import "custom";

.secondary-headline {
  color: $white;
  font-size: 64px;

  @include media-breakpoint-down(md) {
    font-size: 40px;
  }
}
